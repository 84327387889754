import React from 'react'
import Select from 'react-select'

import Tree from './tree.json'
import './App.css'

const chartTypes = ['chart_iq', 'trading_view']
const domainName = false ? 'http://localhost:5000' : 'https://ctb-node.streak.ninja'

const  initialState = {
  rawText: '',
  rawTextErr: true,
  rawTextErrMsg: '',
  rawDataKeys: [],
  selectedIndicatorMap: {},
  password: '',
  chart_type: chartTypes[0],
  saveSuccess: false,
  parserErr: false,
  parserErrMsg: '',
  downloadErr: false,
  downloadErrMsg: '',
  loading: false,
  downloading: false,
  additionalIndicators: {},
  optionsMap: {}
}

class App extends React.PureComponent {
  state = {
    ...initialState,
    authenticated: false,
  }

  paramsToRawParams = {}

  contentRef = React.createRef()

  rawJson = {}

  finalJson = {}

  componentDidMount() {
    this.treeKeys = Object.keys(Tree.indicators)
    this.treeKeysNode = this.treeKeys.map(keys => ({
      label: Tree.indicators[keys] ? Tree.indicators[keys].name : keys,
     value: keys,
    }))
    this.treeKeysNode.unshift({ label: '-', value: '' })
  }

  onChange = (e, field) => {
    if (field === 'rawText') {
      let extra = { rawTextErr: false }
      try {
        if (e.target.value) JSON.parse(e.target.value)
      } catch (err) {
        console.log(err.message, '-----invalid json')
        extra = { rawTextErr: true, rawTextErrMsg: err.message }
      }
      this.setState({ rawText: e.target.value, ...extra, saveSuccess: false, parserErr: false })
    }
  }

  populateIndicators = (e) => {
    e.preventDefault()
    const { rawTextErr, rawText } = this.state
    if (rawTextErr || !rawText) return
    this.rawJson = this.getRawJson(rawText)
    const rawDataKeys = Object.keys(this.rawJson)
    this.setState({ rawDataKeys, selectedIndicatorMap: {},
      parserErr: false, saveSuccess: false, })
  }

  getRawJson = (rawText) => {
    const { chart_type } = this.state
    if(chart_type === chartTypes[0]) {
      return JSON.parse(rawText)
    } else {
      const json = JSON.parse(rawText)
      const studyObjects = []
      json.forEach(payloadItem => {
        if (payloadItem.sources) {
          studyObjects.push(...payloadItem.sources.filter(item => item.type.toLowerCase().includes('study')
          ))
        }
      })
      // const studyObjects = json.sources.filter(source => source.type === 'Study')
      const finalJson = {}
      studyObjects.forEach(study => {
        finalJson[study.metaInfo.shortId] = study.metaInfo
      })
      return finalJson
    }
   }

  onIndiKeyChange = ({ value }, chartIndiKey) => {
    this.setState(prevState => {
      const indiKeyArr = prevState.selectedIndicatorMap[chartIndiKey] || []
      if (indiKeyArr.some(item => item === value)) {
        return ({})
      } else {
        indiKeyArr.push(value)
      }
      return ({
        selectedIndicatorMap: {
          ...prevState.selectedIndicatorMap,
          [chartIndiKey]: indiKeyArr,
        }
      })
    })
  }

  onIndiParamsChange = ({ value }, indiKey, tChartKey, chartIndiKey) => {
    const mainObj = this.paramsToRawParams[chartIndiKey] || {}
    let modObj = mainObj[tChartKey]
      ? mainObj[tChartKey]
      : {}
    modObj = {
      ...modObj,
      [indiKey]: value,
    }
    this.paramsToRawParams = {
      ...this.paramsToRawParams,
      [chartIndiKey]: {
        ...mainObj,
        [tChartKey]: modObj,
      }
    }
  }

  onAddMoreIndi = (chartIndiKey, type) => {
      const { additionalIndicators, selectedIndicatorMap } = this.state
      const modObj = { ...additionalIndicators }
      let modIndicatorMap = { ...selectedIndicatorMap }
      if (type === 'add') {
        if (modObj[chartIndiKey]) {
          modObj[chartIndiKey].push({
            chartKey: chartIndiKey, id: `chartIndiKey_${modObj[chartIndiKey].length}`
          })
        } else {
          modObj[chartIndiKey] = [{
            chartKey: chartIndiKey, id: `chartIndiKey_0`
          }]
        }
      } else if (modObj[chartIndiKey]) {
        const deletedIndi = modObj[chartIndiKey].pop()
        if (modIndicatorMap[chartIndiKey]) {
          modIndicatorMap =  {
            ...modIndicatorMap,
            [chartIndiKey]: modIndicatorMap[chartIndiKey].filter(item => item === deletedIndi)
          }
        }
      }
      this.setState({
        additionalIndicators: modObj,
        selectedIndicatorMap: modIndicatorMap
      })
  }

  onInputChange = (e, indiKey, paramsKey, param) => {
    const { optionsMap } = this.state
    let modMap = { ...optionsMap }
    let modIndiMap = modMap[indiKey] || {}
    if (modIndiMap[paramsKey]) {
      modIndiMap[paramsKey] = {
        ...modIndiMap[paramsKey],
        [param]: e.target.value
      }
    } else {
      modIndiMap = {
        [paramsKey]: {
          [param]: e.target.value
        }
      }
    }
    modMap = {
      ...modMap,
      [indiKey]: {
        ...modIndiMap
      }
    }
    console.log(modMap, 'lol map')
    this.setState({ optionsMap: modMap })
  }

  addOptionsMap = (paramsKey, paramsArr) => {
    const { optionsMap } = this.state
    let modOptionsMap = { ...optionsMap }
    if (optionsMap[paramsKey] && optionsMap[paramsKey][paramsArr[0]]) {
      delete modOptionsMap[paramsKey][paramsArr[0]]
    } else {
      let modParamsObj = modOptionsMap[paramsKey] || {}
      let oldObj = modParamsObj[paramsArr[0]] || {}
      paramsArr[4].forEach(item => {
        oldObj = {
          ...oldObj,
          [item]: item,
        }
      })
      modOptionsMap = {
        ...modOptionsMap,
        [paramsKey]: {
          ...modParamsObj,
          [paramsArr[0]]: oldObj
        }
      }
    }
    this.setState({ optionsMap: modOptionsMap })
  }

  renderIndicatorForm = () => {
    const {
      rawDataKeys, selectedIndicatorMap, additionalIndicators, optionsMap, chart_type
    } = this.state
    if (rawDataKeys.length === 0) return

    return rawDataKeys.map((chartIndiKey, index) => {
      let indiParams = []
      if (chart_type === chartTypes[0]) {
        indiParams = this.rawJson[chartIndiKey].inputs ? Object.keys(this.rawJson[chartIndiKey].inputs) : []
      } else if (chart_type === chartTypes[1]) {
        indiParams = this.rawJson[chartIndiKey].inputs ? this.rawJson[chartIndiKey].inputs : []
      }
      const paramsKeyArr = selectedIndicatorMap[chartIndiKey]
      return (
        <div className="indicatorForm" id={chartIndiKey}>
          <div className="indicatorHeader">
            <h3 className="indicatorTitle">{`${index + 1}. ${chartIndiKey}`}</h3>
            <Select
              name={`indicators_${chartIndiKey}`}
              className="parserSelect indicatorSelect"
              onChange={e => this.onIndiKeyChange(e, chartIndiKey)}
              options={this.treeKeysNode}
              id={`indicatorsKey_${chartIndiKey}`} />
              {additionalIndicators[chartIndiKey] && additionalIndicators[chartIndiKey].map((extraChartObj) => {
                const { chartKey, id } = extraChartObj
                return (
                  <Select
                    key={id}
                    name={`indicators_${chartKey}`}
                    className="parserSelect indicatorSelect"
                    onChange={e => this.onIndiKeyChange(e, chartKey)}
                    options={this.treeKeysNode}
                    id={`indicatorsKey_${id}`}
                  />
                )
              })}
            <button type="button" onClick={() => this.onAddMoreIndi(chartIndiKey, 'add')} >
              Add
            </button>
            <button type="button" onClick={() => this.onAddMoreIndi(chartIndiKey, 'delete')} >
              Delete
            </button>
          </div>
          {paramsKeyArr && paramsKeyArr.map(paramsKey => {
            if (paramsKey && Tree.indicators[paramsKey].params) {
              return (
            <div className="paramsList">
              <div className='paramsListHeader'>
                <p>{`${paramsKey} key`}</p>
                <p> key</p>
              </div>
              {Tree.indicators[paramsKey].params.map(paramsArr => {
                let chartParamsOptions = [{ label: '-', value: '' }]
                  indiParams.forEach(p =>{
                    if (typeof p === 'object') {
                      chartParamsOptions.push({ label: p.name, value: p.id })
                    } else {
                      chartParamsOptions.push({ value: p, label: p })
                    }
                  }
                    )
                return (
                  <div className="paramsListRow" id={paramsArr[0]}>
                     <div>
                      <p>{paramsArr[0]}</p>
                      {paramsArr[3] === 'dropdown' && (<button type="button" className='toggleOptionMapBtn' onClick={() => this.addOptionsMap(paramsKey, paramsArr)}>
                        {optionsMap[paramsKey] && optionsMap[paramsKey][paramsArr[0]] ? 'Unmap fields' : 'Map fields'}
                      </button>)}
                        <div >
                          {optionsMap[paramsKey] && optionsMap[paramsKey][paramsArr[0]]
                            && Object.keys(optionsMap[paramsKey][paramsArr[0]]).map(paramsTuppleKey => {
                              const enteredParam = optionsMap[paramsKey][paramsArr[0]][paramsTuppleKey] || ''
                            return (
                              <div className='optionsMapRow' id={paramsTuppleKey}>
                                <p>{paramsTuppleKey}</p>
                                <input onChange={e => this.onInputChange(e, paramsKey, paramsArr[0], paramsTuppleKey)} value={enteredParam} />
                              </div>
                            )
                          })}
                        </div>
                     </div>

                     <Select
                       name={`params_${chartIndiKey}_${paramsArr[0]}`}
                       className="parserSelect paramsSelect"
                       onChange={e => this.onIndiParamsChange(e, paramsArr[0], paramsKey, chartIndiKey)}
                        options={chartParamsOptions}
                      />
                  </div>
                )
              })}
            </div>
              )
            }
            return null
          })}
        </div>
      )
    })
  }

  postData = async (body) => {
    const { chart_type, loading } = this.state
    console.log(body, '---sending data')
    if (loading) {
      return
    }
    this.setState({ loading: true })
    try {
      const responseData = await fetch(`${domainName}/parser/indicator`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chart_type,
          data_map: body,
        })
      })
      .then(resp => resp.json())
      .catch(err => {
        this.setState({ parserErr: true, parserErrMsg: err.message })
      })
      console.log(responseData,'----post data resp')
      if (responseData) {
        if (responseData.status === 'success') {
          this.setState({ saveSuccess: true })
        } else if (responseData.error_msg) {
          this.setState({ parserErr: true, parserErrMsg: responseData.error_msg })
        }
      }
    } catch (err) {
      console.log(err)
    }
    this.setState({ loading: false })
  }

  downloadData = async (body) => {
    const { downloading } = this.state
    console.log(body, '---sending data')
    if (downloading) {
      return
    }
    this.setState({ downloading: true })
    try {
      const responseData = await fetch(`${domainName}/parser/download_parsed_tree`)
      .then(resp => resp.json())
      .catch(err => {
        this.setState({ downloadErr: true, downloadErrMsg: err.message })
      })
      console.log(responseData,'----post data resp')
      if (responseData) {
        if (responseData.status === 'success') {
          const json = JSON.stringify(responseData.data, null, 2);
          const blob = new Blob([json], { type: "application/json" });
          const href = URL.createObjectURL(blob);
    
          // create "a" HTLM element with href to file
          const link = document.createElement("a");
          link.href = href;
          link.download = "parsedTree.json";
          document.body.appendChild(link);
          link.click();
    
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.setState({ downloadSuccess: true })
        } else if (responseData.error_msg) {
          this.setState({ downloadErr: true, downloadErrMsg: responseData.error_msg })
        }
      }
    } catch (err) {
      console.log(err)
    }
    this.setState({ downloading: false })
  }

  getReverseOptionsMap = (optionsMap, treeKey) => {
    let modOptionsMap = {}
    if (optionsMap[treeKey]) {
      // reversing the key value pair so that backend can easily map
      Object.keys(optionsMap[treeKey]).forEach(paramKey => {
        let paramObj = optionsMap[treeKey][paramKey]
        if (paramObj) {
          let newObj = {}
          Object.keys(paramObj).forEach(paramOptionKey => {
            if (paramObj[paramOptionKey]) {
              newObj = {
                ...newObj,
                [paramObj[paramOptionKey]]: paramOptionKey
              }
            }
          })
          paramObj = newObj
        }
        modOptionsMap = {
          ...modOptionsMap,
          [paramKey]: paramObj
        }
      })
    }
    return modOptionsMap
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { selectedIndicatorMap, optionsMap } = this.state
    this.finalJson = {}
    if (Object.keys(selectedIndicatorMap).length !== 0) {
      Object.keys(selectedIndicatorMap).forEach(rawKey => {
        const key = this.rawJson[rawKey].type || rawKey
        // /[a-zA-Z\s]+/g.exec(rawKey) ? /[a-zA-Z\s]+/g.exec(rawKey)[0] : rawKey
        const treeKeyArr = selectedIndicatorMap[rawKey]
        if (treeKeyArr) {
          treeKeyArr.forEach(treeKey => {
            if (this.paramsToRawParams[rawKey] && this.paramsToRawParams[rawKey][treeKey]) {
              if (this.finalJson[key]) {
                const tIndicators = this.finalJson[key].tIndicators || []

                tIndicators.push({
                  tKey: treeKey,
                  params: this.paramsToRawParams[rawKey][treeKey],
                  optionsMap: this.getReverseOptionsMap(optionsMap, treeKey),
                })
              } else {
                this.finalJson[key] = {
                  tKey: treeKey,
                  tIndicators: [{
                    tKey: treeKey,
                    params: this.paramsToRawParams[rawKey][treeKey],
                    optionsMap: this.getReverseOptionsMap(optionsMap, treeKey),
                  }],
                }
              }
            }
          })
        }
      })
    this.postData(this.finalJson)

    } else {
      console.log('---empty raw to keys map')
    }
  }

  onDownload = (e) => {
    e.preventDefault()
    this.downloadData()
  }


  render() {
    const {
      authenticated, password, parserErrMsg, parserErr, chartType, rawDataKeys,
      rawTextErr, rawTextErrMsg, saveSuccess, loading, downloading, downloadSuccess,
      downloadErr, downloadErrMsg,

    } = this.state
    if (!authenticated) {
      return (
        <div>
        <input type="password" onChange={(e) => this.setState({ password: e.target.value })} />
        <button type="button" onClick={() => {
          if (password === '123123123') {
            this.setState({ authenticated: true })
          }
        }}>Log in</button>
        </div>
      )
    }
    return (
      <div className="App">
        <h2 className="title">Streak Parser</h2>
        {rawDataKeys.length === 0 ? null : (
          <div style={{ textAlign: 'right' }}>
          {downloadSuccess && <p style={{ color: 'green', fontSize: 12, margin: '10px 0' }}>Downloaded successfully!!</p>}
          {saveSuccess && <p style={{ color: 'green', fontSize: 12, margin: '10px 0' }}>Saved successfully!!</p>}
          {parserErr && <p style={{ color: 'red', fontSize: 12, margin: '10px 0' }}>{parserErrMsg}</p>}
          {downloadErr && <p style={{ color: 'red', fontSize: 12, margin: '10px 0' }}>{downloadErrMsg}</p>}

        <button disabled={loading} type="button" onClick={e => this.onSubmit(e)} className="saveBtn">
         {loading ? 'Saving' : 'Save'}
        </button>
        <button type="button" onClick={e => this.onDownload(e)} className="saveBtn" style={{ marginLeft: 20 }}>
         {downloading ? 'Downloading' : 'Download'}
        </button>
          </div>
        )}
        <div className='parserInputSection'>
          <div className="chartType">
            <p>Chart type</p>
            <select onChange={e => this.setState({ ...initialState, chart_type: e.target.value,  })} className="parserSelect" value={chartType}>
              {chartTypes.map(ct => <option key={ct} value={ct}>{ct}</option>)}
            </select>
          </div>
          <textarea rows={5} type="input" name="payload" placeholder="Enter your json here" className="parserInput" onChange={(e) => this.onChange(e, 'rawText')} />
          <button onClick={(e) => this.populateIndicators(e)} className="parserBtn">
            Parse
          </button>
          {rawTextErr && <p style={{ color: 'red', fontSize: 12, margin: '10px 0' }}>{rawTextErrMsg}</p>}
        </div>
        <div className='content' ref={this.contentRef}>
          {this.renderIndicatorForm()}
        </div>
        {/* {rawDataKeys.length === 0 ? null : (
          <>
          {downloadSuccess && <p style={{ color: 'green', fontSize: 12, margin: '10px 0' }}>Saved successfully!!</p>}
          {saveSuccess && <p style={{ color: 'green', fontSize: 12, margin: '10px 0' }}>Saved successfully!!</p>}
          {parserErr && <p style={{ color: 'red', fontSize: 12, margin: '10px 0' }}>{parserErrMsg}</p>}
          {downloadErr && <p style={{ color: 'red', fontSize: 12, margin: '10px 0' }}>{downloadErrMsg}</p>}

        <button disabled={loading} type="button" onClick={e => this.onSubmit(e)} className="saveBtn">
         {loading ? 'Saving' : 'Save'}
        </button>
        <button type="button" onClick={e => this.onDownload(e)} className="saveBtn" style={{ marginLeft: 20 }}>
         {downloading ? 'Downloading' : 'Download'}
        </button>
          </>
        )} */}
      </div>
    )
  }

}

export default App
